export default class MoneyConverter {
    static euroToCents(value: number): number {
        return value * 100;
    }

    static centsToEuro(value: number): number {
        return value / 100;
    }

    static toDecimalFormat(value?: number): string {
        if (value === undefined) {
            return "";
        }
        
        return value.toFixed(2);
    }
}
