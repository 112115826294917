
import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {TABLE_FOOTER_OPTIONS} from '@/misc/TableFooterOptions';
import {ROUTE_CONTAINERS_SUMMARY} from "@/router/routes";
import ContainerType from "@/models/ContainerType.model";
import MoneyConverter from "@/misc/MoneyConverter";
import {ContainerTypeStatus} from "@/enum/ContainerTypeStatus";

@Component
export default class ContainerListComponent extends Vue {
  /**
   * list with accepted container types
   */
  @Prop({required: true})
  public containers!: ContainerType[];

  /**
   * list with requested container types
   */
  @Prop({required: true})
  public requestedContainers!: ContainerType[];

  /**
   * flag that indicates if the accepted or the requested container list should be shown
   */
  @Prop({default: false})
  public showRequestedContainers!: boolean;

  /**
   * callback function that is called when the user clicks on the edit action inside the action menu of an entry
   */
  @Prop({required: true})
  public startEditing!: (container: ContainerType) => void;

  /**
   * callback function that is called when the user clicks on the delete action inside the action menu of an entry
   */
  @Prop({required: true})
  public deleteItem!: (container: ContainerType) => void;

  /**
   * callback function that is called once the proved item status should changed
   */
  @Prop({required: true})
  public onProvedItemsStatusChanged!: (container: boolean) => void;

  /**
   * flag that indicates if the table is loading
   */
  @Prop()
  private isLoading!: boolean;

  /**
   * search string to query the table for certain search queries
   */
  @Prop({default: ''})
  private search!: string;

  /**
   * holds the information of the selectedItem
   */
  private selectedItem: ContainerType = new ContainerType();

  private get hasMultipleContainers() {
    return this.containers.length > 1;
  }

  /**
   * returns the items of the table, depending on the requestedContainers flag either shows all accepted ContainerType
   * or the requested ones
   */
  private get tableItems() {
    return this.showRequestedContainers ? this.requestedContainers : this.containers;
  }

  /**
   * Format to a Decimal Number
   * @param val Number to Format into a Decimal Format
   */
  private toDecimalFormat = (val: number) => MoneyConverter.toDecimalFormat(val).replace('.', ',');

  /**
   * options for the footer
   * @private
   */
  private footerOptions = TABLE_FOOTER_OPTIONS;

  /**
   * checks if the container type has a company
   */
  private isPBOrSameCompany(item: any) {
    // TODO: remove for now
    // return item.company || this.$hasRole(AccountType.PB);
    return false;
  }

  @Watch('isLoading', {deep: true, immediate: true})
  private onLoadingStateChanged() {
    // checks if the table is loading
    if (this.isLoading) return;

    // tries to fetch the request id from the route
    const typeId = this.$route.params.containerTypeId;
    if (!typeId) return;

    // tries to find the container type in the lists based on the requestId
    const pendingItem = this.containers.find((c) => c.id === typeId);
    const handledItem = this.requestedContainers.find((c) => c.id === typeId);

    const item = pendingItem ?? handledItem;
    if (!item) return;
    this.selectedItem = item;

    // checks if the containerType was found inside the handled list and opens the handled container type list by
    // default to show the expanded item right from the start
    if (handledItem) {
      this.onProvedItemsStatusChanged(true);
    }
  }

  private isSelectedItem(type: ContainerType): boolean {
    return this.selectedItem.id === type.id;
  }

  /**
   * headers of the table
   */
  private get headers() {
    // inits the header array
    const headers = [
      {text: this.$t('CONTAINERS.NAME'), align: 'start', sortable: true, value: 'name'},
      {text: this.$t('CONTAINER_TYPE_REQUESTS.PRIVATE_NOTE'), align: 'start', sortable: true, value: 'internalInstruction'},
      {text: this.$t('CONTAINER_TYPE_REQUESTS.PUBLIC_NOTE'), align: 'start', sortable: true, value: 'description'},
      // TODO: reintroduce later
      // {text: this.$t('CONTAINERS.BONUS_RATE'), align: 'start', sortable: true, value: 'lastPenaltyRate'},
      {text: this.$t('CONTAINERS.INITIAL_STOCK'), align: 'start', sortable: true, value: 'stock'}
    ];

    // checks which table is shown and adds the actions column when the user is seeing all accepted ContainerType list
    if (!this.showRequestedContainers) {
      headers.push({text: this.$t('CONTAINERS.ACTIONS'), align: 'start', sortable: false, value: 'actions'});
    }

    // checks which table is shown and adds the status column when the user is seeing all requested container types
    if (this.showRequestedContainers) {
      headers.push({text: this.$t('GENERAL.STATUS'), align: 'start', sortable: false, value: 'status'});
    }

    return headers;
  }

  /**
   * is called when the item is clicked
   * @param item
   * @private
   */
  private onItemClicked(item: any) {
    this.$router.push({name: ROUTE_CONTAINERS_SUMMARY, params: {...this.$route.params, containerTypeId: item.id}});
  }

  /**
   * returns if the containerType is still in request
   * @param item
   * @private
   */
  private isPending(item: ContainerType) {
    return item.status === ContainerTypeStatus.REQUESTED;
  }
}
